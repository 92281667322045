/* ==========================================================================
   TOP
   ======================================================================= */
.top-bg {
  background: url('../img/top/bg.jpg') 0 0 repeat;
}

/* hero */
.commitment {
  position: relative;
}
.commitment__ttl {
  text-align: center;
}


/* features */
.features__item img {
  width: 100%;
  height: auto;
}
.features__img {
  position: relative;
}
.features__img a {
  width: 100%;
  height: 100%;
  display: block;
}
.no-touchevents .features__img a {
  transition: background 0.4s ease-out;
}
.no-touchevents .features__img a:hover {
  background: rgba(255, 255, 255, 0.4);
}


/* clients */
.clients__list li {
  float: left;
  box-sizing: border-box;
  margin: 0 0 10px;
  padding: 24px 30px;
  font-size: 18px;
  background: #fff;
}
.clients__list li.has-no-bg {
  background: none;
}


/* news (TODO: responsive, fonts...) */
.lastnews {
  position: relative;
  list-style-type: none;
  padding-bottom: 48px; /* FIXME: ; */
}
.lastnews li {
  width: 86.74%;
  margin: 19px auto 0;
  border-top: 1px solid #c5ceda;
}
.lastnews li:first-child {
  border-top: none;
}
.lastnews dt {
  margin: 27px 0 5px;
  font-size: 13px;
  color: #000;
  font-family: verdana, yugo, sans-serif;
}
.lastnews li:first-child + li dt,
.lastnews li:first-child + li + li dt {
  margin: 21px 0 0;
}
.lastnews dd {
  display: inline;
  margin: 0;
  padding: 1px 0;
  font-size: 14px;
  color: #023f96;
  line-height: 2.15;
  border-bottom: 1px dotted #023f96;
}
.lastnews a:not('.lastnews__link') {
  color: #023f96;
  text-decoration: none;
}


@media all and (max-width: 767px) {

  /* hero */
  .commitment {
    width: 100%;
    box-sizing:  border-box;
    margin: 0 auto;
    padding: 20px 20px 0;
  }
  .commitment__imgbox {
    position: relative;
    padding-top: 79.3%;
    background: url('../img/top/sp-commitment_bg.jpg') 0 0 / cover;
  }
  .commitment__ttl {
    width: 63.28%;
    position: absolute;
    top: 50%;
    margin: -25% auto 0;
    left: 0;
    right: 0;
  }
  .commitment__list > li img {
    width: 100%;
    height: auto;
  }

  /* features */
  .features {
    width: 100%;
    margin: 40px auto 0;
    padding: 0 20px;
    box-sizing:  border-box;
  }
  .features__item.feature2-bnr {
    margin-top: 40px;
  }
  .features__item.feature1-bnr > .features__img {
    padding-top: 82%;
    background: url('../img/top/sp-feature_message_bg.jpg') 0 0 / cover;
  }
  .features__item.feature2-bnr > .features__img {
    padding-top: 82%;
    background: url('../img/top/sp-feature_factory_bg.jpg') 0 0 / cover;
  }
  .features__item.feature1-bnr .features__copy {
    position: absolute;
    width: 77.41%;
    bottom: 50%;
    margin: 0 auto -15%;
    left: 0;
    right: 0;
  }
  .features__item.feature2-bnr .features__copy {
    width: 58.62%;
    position: absolute;
    top: 25px;
    left: 15px;
  }


  /* story */
  .story {
    width: 100%;
    margin: 40px auto 0;
    padding: 0 20px;
    box-sizing:  border-box;
  }
  .story .coming-soon {
    padding-top: 167.3%;
    background: url('../img/top/sp-production_story_commingsoon_bg.jpg') 0 0 / cover no-repeat;
  }

  /* information */
  .information {
    width: 100%;
    margin: 40px 0 0;
    padding: 0 20px;
    box-sizing:  border-box;
  }
  .lastnews {
    padding: 10px 0 30px;
    background: #ffffff;
  }
  .lastnews:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 5px;
    background: url('../img/dummy/news_border.png') repeat-x center top;
  }
  .lastnews li:last-child {
    margin-bottom: 30px;
  }
  .lastnews dt {
    font-size: 12px;
  }
  .lastnews dd {
    font-size: 13px;
    line-height: 1.8;
  }
  .inhouse-prod {
    margin-top: 40px;
  }

  /* clients */
  .clients {
    margin-top: 40px;
    padding: 0 20px;
    box-sizing:  border-box;
  }
  .clients__list {
    margin-top: 25px;
  }
  .clients__list li {
    width: 100%;
    padding: 15px 10px;
    font-size: 13px;
  }
  .clients__list li.has-no-bg {
    margin-top: -15px;
  }

  /* sns */
  .sns > .section-large__wrapper {
    background: #fbfbfb;
    padding-bottom: 30px; /* FIXME */
  }
  .facebook__list {
    padding: 20px 10px 30px;
  }
  .facebook__list > h2,
  .cookpad__list > h2 {
    margin-bottom: 0px; /* FIXME */
  }
  .sns_ttl {
    margin: 0 auto 15px;
    text-align: center;
    font-size: 12px;
    font-family: verdana, yugo, sans-serif;
  }
  .cookpad__list {
    padding-bottom: 30px;
  }
  .cookpad__innerlist li {
    width: calc(100% / 3);
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 0;
    overflow: hidden;
    border: 3px solid #fbfbfb;
  }
  .cookpad__innerlist li img {
    width: 100%;
    height: auto;
  }
  .cookpad__hoverpanel {
    display: none;
  }

}

@media all and (min-width: 768px) and (max-width: 1300px) {

  .cookpad__innerlist .hoverpanel__txt {
    font-size: 10px;
  }

  .cookpad__innerlist .hoverpanel__label {
    bottom: 10px;
    font-size: 13px;
}

}

@media all and (min-width: 768px) {

  /* hero */
  .commitment {
    width: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    background: #ebf0f2;
    padding-top: 40px;
  }
  .commitment__partners {
    position: absolute;
    bottom: 20px;
    right: 10px;
    margin-top: 2vw;
    align-self: end;
  }
  .commitment__imgbox {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: calc(100% - 420px);
    height: 414px;
    position: relative;
    float: left;
    background: url('../img/top/commitment_bg.jpg') 0 0 / cover;
  }
  .commitment__ttl {
    width: 367px;
    padding: 0 20px;
  }
  .commitment__listbox {
    width: 420px;
    float: right;
  }
  .commitment__list {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .commitment__list > li:not(.c-top-link) {
    height: 125px;
    display: flex;
    align-content: center;
    align-items: center;
    align-content: stretch;
    justify-content: center;
    flex: 1 1 100%;
  }
  .commitment__list > li:nth-child(1),
  .commitment__list > li:nth-child(3) {
    background: #ffffff;
  }
  .commitment__list > li:nth-child(2) {
    background: #f9f9f9;
  }
  .commitment__list > li img {
    width: 100%;
    max-width: 390px;
    height: auto;
  }
  .commitment__list a {
    transition: opacity 0.4s ease-out;
  }
  .commitment__list a:hover {
    opacity: 0.8;
  }

  /* features */
  .features {
    margin-top: 50px;
  }
  .features__list > li {
    position: relative;
    font-size: 0;
  }
  .features__item {
    width: 50%;
    float: left;
    position: relative;
  }
  .features__link  {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .features__item.feature1-bnr > .features__img {
    height: 400px;
    background: url('../img/top/feature_message_bg.jpg') 0 0 / cover;
  }
  .features__item.feature2-bnr > .features__img {
    height: 400px;
    background: url('../img/top/feature_factory_bg.jpg') 0 0 / cover;
  }
  .features__item.feature1-bnr .features__copy {
    max-width: 468px;
    height: 131px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding:  0 20px;
  }
  .features__item.feature2-bnr .features__copy {
    max-width: 240px;
    height: 131px;
    position: absolute;
    top: 50px;
    left: 10px;
    margin: auto;
    padding:  0 20px;
  }


  /* story */
  .story {
    width: 100%;
    max-width: 1155px;
    margin: 50px auto 0;
  }
  .story .coming-soon {
    width: 100%;
    height: 400px;
    background: url('../img/top/production_story_commingsoon_bg.jpg') 0 0 / 100% no-repeat;
  }


  /* information */
  .information {
    display: flex;
    margin: 50px 0 0;
    background: #fbfbfb;
  }
  .lastnews {
    position: relative;
    width: 36.36%;
    float: left;
  }
  .lastnews::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    content: "";
    width: 100%;
    height: 5px;
    background: url('../img/dummy/news_border.png') repeat-x center top;
  }
  .lastnews__link {
      position: absolute;
      bottom: 0;
      left: 0;
  }
  .inhouse-prod {
    width: 63.62%;
    float: left;
  }
  .inhouse-prod .flexslider {
    border: 0;
    margin: 0;
  }

  /* clients */
  .clients {
    width: 100%;
    margin: 50px auto 0;
  }
  .clients__list {
    padding: 0;
    list-style-type: none;
  }
  .clients__list {
    width: 100%;
    max-width: 880px;
    margin: 30px auto 0;
  }
  .clients__list li {
    width: 48.5%;
  }
  .clients__list li:nth-child(even) {
    margin-left: 20px;
  }

  /* sns */
  .sns {
    background: #fbfbfb;
  }

  .sns__list {
    width: 93.26%;
    margin: 43px auto 0;
    padding: 43px 0 77px;
    list-style-type: none;
  }
  .sns__list h2 {
    margin: 0 0;
    text-align: center;
  }
  .sns_ttl {
    font-family: verdana, yugo, sans-serif;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 20px;
  }
  .facebook__list {
    width: 47.17%;
    float: left;
  }
  .facebook__list .fb-page {
    text-align: center;
    margin-top: 5px;
  }
  .cookpad__list {
    width: 47.17%;
    float: left;
    margin: 0 0 0 5.66%;
  }
  .cookpad__innerlist {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .cookpad__innerlist li {
    width: calc(100% / 3);
    max-width: 248px;
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 0;
    overflow: hidden;
    border: 5px solid #fbfbfb;
  }
  .cookpad__img > img {
    width: 100%;
    height: auto;
  }
  .cookpad__hoverpanel {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    text-align: center;
    line-height: 1.5;
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    background: #1b1e78;
  }
  .hoverpanel__txt {
    position: relative;
    top: -20px;
  }
  .hoverpanel__label {
    width: 50%;
    display: block;
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius:  10px;
    border:  2px solid #ffffff;
    background: #4e5396;
    font-style: normal;
  }
  .no-touchevents .cookpad__innerlist li img,
  .no-touchevents .cookpad__innerlist li .cookpad__hoverpanel {
    transition: all 0.5s ease-out;
  }
  .no-touchevents .cookpad__innerlist li:hover .cookpad__hoverpanel {
    opacity: 1;
  }
  .no-touchevents .cookpad__innerlist li:hover img {
    opacity: 0;
    filter: blur(10px);
  }

}


/* --------------------------------------------------------------------------
   Top Page Components
   ----------------------------------------------------------------------- */
/* patners logo */
.c-partners {}
.c-partners__item {
  display: inline-block;
  padding: 10px;
  background: #ffffff;
  font-size: 12px;
  line-height: 1.3;
}

.c-partners__logo {
  width: 100%;
  height: 20px;
  display: block;
  margin-bottom: 5px;
}
.c-partners__item:nth-child(1) .c-partners__logo {
  background: url('../img/common/logo_fssc22000.png') center center / contain no-repeat;
}
.c-partners__item:nth-child(2) .c-partners__logo {
  background: url('../img/common/logo_jas.png') center center / contain no-repeat;
  background-size: 51%;
}

/* link btn */
.c-top-link a {
  width: 100%;
  height: 100%;
  display: block;
}
.c-top-link__body a::after {
  content:  '';
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  background: url('../img/top/ctoplink_icn_bg.gif') 0 0 / cover;
}


@media all and (max-width: 767px) {

  /* patners logo */
  .c-partners {
    display: flex;
  }
  .c-partners__item {
    width: 50%;
    font-size: 11px;
    background: #f9f9f9;
  }
  .c-partners__item:nth-child(1) {
    border-right: 1px solid #ffffff;
  }
  .c-partners__logo {
    padding-top: 18%;
  }
  .c-partners__item:nth-child(1) .c-partners__logo {
    background-size: 82%;
  }
  .c-partners__item:nth-child(2) .c-partners__logo {
    background-size: 36%;
  }

  /* link btn */
  .c-top-link {
    width: 82.76%;
    margin: 20px auto 0;
    background: #023f96;
  }
  .c-top-link__body {
    padding-right: 30px;
    line-height: 2.7;
    position: relative;
    text-align: right;
    font-size: 15px;
    color: #ffffff;
    font-family: YakuHanMP, NotoSerif, "Yu Mincho", "YuMincho", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  }
  .c-top-link a {
    text-align: center;
  }

}

@media all and (min-width: 768px) {

  /* patners logo */
  .c-partners__item {
    width: 130px;

  }
  .c-partners__logo {
    width: 100%;
    display: block;
    padding-top: 20px;
  }

  /* link btn */
  .c-top-link {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .c-top-link__body {
    height: 40px;
    line-height: 2.5;
    position: relative;
    text-align: right;
    padding-right: 60px;
    font-family: YakuHanMP, NotoSerif, "Yu Mincho", "YuMincho", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 16px;
    letter-spacing: 0.03em;
    color: #ffffff;
  }

}


/* facebook override */
.fb-page, 
.fb-page span, 
.fb-page span iframe[style] { 
    width: 100% !important;
    margin: auto;
}